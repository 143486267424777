import { usePathname } from 'next/navigation';
import { useMemo } from 'react';

import { type TOptional } from '@/types/common';

import getRouteConfig from './getRouteConfig';
import { type TRouteConfig } from './types';

const useRouteConfig = (): TOptional<TRouteConfig> => {
  const path = usePathname();
  return useMemo(() => (path ? getRouteConfig(path) : undefined), [path]);
};

export default useRouteConfig;
