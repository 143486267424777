export type TSubscriptionBaseEvent = { type: string };
export type TSubscriptionListener<E> = (event: E) => void;
export type TSubscriptionDisposer = () => void;

export default class Subscription<E extends TSubscriptionBaseEvent = TSubscriptionBaseEvent> {
  private listeners: Record<string, TSubscriptionListener<E>[]> = {};

  emit(event: E): void {
    const list = this.listeners[event.type];
    if (list?.length) list.forEach((listener: TSubscriptionListener<E>) => listener(event));
  }

  on(type: string, listener: TSubscriptionListener<E>): TSubscriptionDisposer {
    const list = (this.listeners[type] = this.listeners[type] || []);
    if (!list.includes(listener)) list.push(listener);
    return () => this.off(type, listener);
  }

  off(type: string, listener: TSubscriptionListener<E>): void {
    const list = this.listeners[type];
    const i = list?.indexOf(listener) ?? -1;
    if (i !== -1) list!.splice(i, 1);
  }

  has(type: string): boolean {
    return !!this.listeners[type]?.length;
  }
}
