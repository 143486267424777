import type { ApolloError, ApolloQueryResult } from '@apollo/client';
import type { GraphQLErrors, NetworkError } from '@apollo/client/errors';

import type { TOptional } from '@/types';

export const extractQueryResultError = <R = unknown>({ error, errors }: ApolloQueryResult<R>): unknown =>
  apolloErrorToJson(error) || graphQLErrorsToJson(errors);

export const apolloErrorToJson = (error: TOptional<ApolloError>) => {
  if (error) {
    const { clientErrors, extraInfo, graphQLErrors, message, name, networkError, protocolErrors } = error;
    return {
      clientErrors: clientErrors?.map(errorToString),
      extraInfo,
      graphQLErrors: graphQLErrorsToJson(graphQLErrors),
      message,
      name,
      networkError: networkErrorToJson(networkError),
      protocolErrors,
    };
  }
};

export const graphQLErrorsToJson = (errors: TOptional<GraphQLErrors>) => {
  if (errors?.length) return errors.map((err) => (err.toJSON ? err.toJSON() : err));
};

export const networkErrorToJson = (error: TOptional<NetworkError>) => {
  if (error) {
    if ('result' in error) {
      const { result, statusCode } = error;
      type R = {
        errors?: TOptional<
          Array<{
            extensions?: TOptional<{ stacktrace?: TOptional<string[]> }>;
          }>
        >;
      };
      (result as R)?.errors?.forEach((item) => {
        delete item?.extensions?.stacktrace;
      });
      return { result, statusCode };
    }
    if ('bodyText' in error) {
      const { bodyText, statusCode } = error;
      return { bodyText, statusCode };
    }
    return errorToString(error);
  }
};

export const errorToString = (error: Error): string => error.message || `${error}`;
