import type { TPromoBannerPerks } from '@/features/promoBanners/types';
import type { TNullable } from '@/infra/types/common';
import type { TOptional } from '@/types/common';

export type TPerksContainer = {
  perks: TNullable<string>;
  perksPriority: TNullable<string>;
  perksSubheading: TNullable<string>;
};

export type TGatherPerks<D extends TPerksContainer> = {
  perks: TOptional<TPromoBannerPerks>;
} & Omit<D, keyof TPerksContainer>;

const gatherPerks = <D extends TPerksContainer>(data: D): TGatherPerks<D> => {
  const { perks, perksPriority, perksSubheading, ...restProps } = data || {};
  return {
    ...restProps,
    perks:
      perks || perksSubheading
        ? {
            priority: perksPriority || undefined,
            subheading: perksSubheading || undefined,
            title: perks || undefined,
          }
        : undefined,
  };
};

export default gatherPerks;
