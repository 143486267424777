import type { TOptional } from '@/types';

export type TIsGarbage = (value: unknown) => boolean;

export const defaultIsGarbage = (value: unknown) => value === undefined;

const cleanup = (data: unknown, isGarbage?: TOptional<TIsGarbage>): unknown => {
  const check = isGarbage || defaultIsGarbage;
  if (!check(data)) {
    if (Array.isArray(data)) {
      return data.map((item: unknown) => cleanup(item, check));
    } else if (data && typeof data === 'object') {
      const entries = Object.entries(data as Record<string, unknown>).filter(([, value]) => !check(value));
      return Object.fromEntries(entries.map(([key, value]) => [key, cleanup(value, check)]));
    } else {
      return data;
    }
  }
};

export default cleanup;
