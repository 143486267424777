import merge from 'lodash/merge';

import ROUTE_CONFIGS from '@/constants/routeConfigs';
import { type TOptional } from '@/types/common';

import isPathMatched from './helpers/isPathMatched';
import { type TRouteConfig } from './types';

const getRouteConfig = (path: string): TOptional<TRouteConfig> =>
  Object.keys(ROUTE_CONFIGS).reduce<TOptional<TRouteConfig>>(
    (acc, pattern) => (isPathMatched(pattern, path) ? merge(acc ?? ({} as TRouteConfig), ROUTE_CONFIGS[pattern]) : acc),
    undefined,
  );

export default getRouteConfig;
