import { useEffect, useState } from 'react';

import { v4 as uuid } from 'uuid';

import { type TRouteConfig } from '@/features/routeConfig/types';
import onlyOnClient from '@/helpers/ssrSafe/onlyOnClient';
import redirect from '@/helpers/ssrSafe/redirect';
import { type TOptional } from '@/types';

const SESSION_KEY = ':session-id:';

const isNewSession = onlyOnClient((): boolean => {
  const { sessionStorage } = window;
  const previous = sessionStorage.getItem(SESSION_KEY);
  if (!previous) sessionStorage.setItem(SESSION_KEY, uuid());
  return !previous;
});

const useRedirectOnEnterByLink = (routeConfig?: TRouteConfig): TOptional<boolean> => {
  const [isRedirected, setIsRedirected] = useState<boolean>();

  useEffect(() => {
    const isEnterByLink = isNewSession();
    const { redirectTo, withParams } = routeConfig?.enterByLink ?? {};
    if (isEnterByLink && redirectTo) {
      redirect(redirectTo, { isReplace: true, withParams });
      setIsRedirected(true);
    }
  }, []);

  return isRedirected;
};

export default useRedirectOnEnterByLink;
