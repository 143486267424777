export const makePromoBannerPolicy = () => ({
  fields: {
    __fetchedAt: {
      read() {
        return Date.now();
      },
    },
  },
  keyFields: ['placeId'],
});

export const promoBannerPolicies = {
  BillboardBanner: makePromoBannerPolicy(),
  DoubleBanner: makePromoBannerPolicy(),
  FeaturetteBanner: makePromoBannerPolicy(),
  LeaderboardBanner: makePromoBannerPolicy(),
  MarqueeBanner: makePromoBannerPolicy(),
  MerchandisingBanner: makePromoBannerPolicy(),
};
