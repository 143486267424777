export const strikethroughPolicies = {
  StrikethroughData: {
    fields: {
      __fetchedAt: {
        read() {
          return Date.now();
        },
      },
    },
    keyFields: ['strikeThroughAddons'],
  },
};
