import type { TPromoBannerEyebrow } from '@/features/promoBanners/types';
import type { GQLEyebrow } from '@/helpers/api/graphql/queries';
import type { TOptional } from '@/types';

export type TEyebrowContainer = Partial<GQLEyebrow>;

export type TGatherEyebrow<D extends TEyebrowContainer> = {
  eyebrow: TOptional<TPromoBannerEyebrow>;
} & Omit<D, keyof TEyebrowContainer>;

const gatherEyebrow = <D extends TEyebrowContainer>(data: D): TGatherEyebrow<D> => {
  const { countdown, emojiField, eyebrowStyle, eyebrowTagColorTheme, eyebrowTagText, eyebrowText, ...restProps } =
    data || {};
  return {
    ...restProps,
    eyebrow:
      countdown || eyebrowTagText || eyebrowText
        ? {
            appearance: eyebrowStyle || undefined,
            countdown: countdown || undefined,
            emoji: emojiField || undefined,
            tag: eyebrowTagText || undefined,
            text: eyebrowText || undefined,
            theme: eyebrowTagColorTheme || undefined,
          }
        : undefined,
  };
};

export default gatherEyebrow;
