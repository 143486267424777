import type { TImage, TMediaSet, TNullableRecord } from '@/infra/types/common';
import type { TOptional } from '@/types';

export type TImageSetContainer = {
  image375: TNullableRecord<TImage>;
  image768: TNullableRecord<TImage>;
  image992: TNullableRecord<TImage>;
  image1200: TNullableRecord<TImage>;
  image1600: TNullableRecord<TImage>;
};

export type TVideoSetContainer = {
  video375: TNullableRecord<TImage>;
  video768: TNullableRecord<TImage>;
  video992: TNullableRecord<TImage>;
  video1200: TNullableRecord<TImage>;
  video1600: TNullableRecord<TImage>;
};

export type TGatherImageSet<D extends TImageSetContainer> = {
  imageSet: TOptional<TMediaSet>;
} & Omit<D, keyof TImageSetContainer>;

export type TGatherVideoSet<D extends TVideoSetContainer> = {
  videoSet: TOptional<TMediaSet>;
} & Omit<D, keyof TVideoSetContainer>;

export type TMakeMediaSetArgs = TImageSetContainer | TVideoSetContainer;

export const gatherImageSet = <D extends TImageSetContainer>(data: D): TGatherImageSet<D> => {
  const { image375, image768, image992, image1200, image1600, ...restProps } = data || {};
  return { ...restProps, imageSet: makeMediaSet({ image375, image768, image992, image1200, image1600 }) };
};

export const gatherVideoSet = <D extends TVideoSetContainer>(data: D): TGatherVideoSet<D> => {
  const { video375, video768, video992, video1200, video1600, ...restProps } = data || {};
  return { ...restProps, videoSet: makeMediaSet({ video375, video768, video992, video1200, video1600 }) };
};

export const makeMediaSet = (images: TMakeMediaSetArgs): TOptional<TMediaSet> => {
  const entries = Object.entries(images).filter(([, value]) => value?.src);
  if (entries.length) {
    const [alt] = entries
      .map(([, value]) => value?.alt)
      .filter(Boolean)
      .sort((a, b) => (b?.length || 0) - (a?.length || 0));
    const srcSet = Object.fromEntries<string>(
      entries
        .filter(([, value]) => value?.src)
        .map(([name, value]) => [name.replace(/^[a-z]+([0-9]+)$/, '$1'), value!.src!]),
    );
    return { alt: alt || '', srcSet };
  }
};
