import type { TStrikethroughData } from '@/features/strikethrough/types';

import useGraphQLUpdater from '@/features/apollo/useGraphQLUpdater';
import { FETCH_STRIKETHROUGH_DATA, type GQLStrikethroughApiAnswer } from '@/helpers/api/graphql/queries';
import { useAppDispatch } from '@/store';

import { strikethroughPolicies } from './apolloPolicies';
import { setAll, strikethroughDataNull } from './slice';

export type TUseStrikethroughUpdaterArgs = {
  beforeStart?: number;
  isEnabled?: boolean;
  repeatInterval: number;
};

const useStrikethroughUpdater = ({ beforeStart, isEnabled, repeatInterval }: TUseStrikethroughUpdaterArgs) => {
  const dispatch = useAppDispatch();

  const onResult = (answer: GQLStrikethroughApiAnswer) => {
    const strikethroughData = answer?.lookup?.strikethroughData?.[0] as unknown as TStrikethroughData;
    dispatch(setAll({ data: strikethroughData || strikethroughDataNull, error: undefined, isLoaded: true }));
  };

  const onError = (error: Record<string, unknown>) => dispatch(setAll({ data: strikethroughDataNull, error }));

  useGraphQLUpdater<GQLStrikethroughApiAnswer>({
    apolloArgs: { typePolicies: strikethroughPolicies },
    beforeStart,
    isEnabled,
    onError,
    onResult,
    query: FETCH_STRIKETHROUGH_DATA,
    repeatInterval,
  });
};

export default useStrikethroughUpdater;
