import { useEffect, useRef } from 'react';

export type TEventType =
  | 'ClosePopup'
  | 'DashboardButtonClick'
  | 'LogoutClick'
  | 'OpenPopup'
  | 'PastSearchClick'
  | 'UpcomingVoyageClick';

export type TListener = (eventDetail: unknown) => void;

const useOnAccountDropdownEvent = (eventType: TEventType, listener: TListener) => {
  const ref = useRef<TListener>();

  useEffect(() => {
    ref.current = listener;
  }, [listener]);

  useEffect(() => {
    const accountDropdown = document.getElementById('booking-account');
    if (accountDropdown) {
      const callback = (event) => {
        ref.current?.(event.detail);
      };

      const fullEventType = `AccountDropdown_${eventType}`;
      window.addEventListener(fullEventType, callback);
      return () => {
        window?.removeEventListener(fullEventType, callback);
      };
    }
  }, [eventType]);
};

export default useOnAccountDropdownEvent;
