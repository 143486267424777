import { resetTravelParty } from '@/ducks/travelParty/slice';
import { useAppDispatch } from '@/store';
import tagmanager from '@/tagmanager';

import useOnAccountDropdownEvent from './useOnAccountDropdownEvent';

const useInitAccountDropdownEvents = () => {
  const dispatch = useAppDispatch();
  useOnAccountDropdownEvent('LogoutClick', () => {
    dispatch(resetTravelParty());
  });
  useOnAccountDropdownEvent('OpenPopup', tagmanager.tracker.accountDropdown.trackAccountDropdownOpens);
};

export default useInitAccountDropdownEvents;
