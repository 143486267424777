import useGraphQLUpdater from '@/features/apollo/useGraphQLUpdater';
import { FETCH_PROMO_BANNERS_V2, type GQLPromoBannersApiAnswer } from '@/helpers/api/graphql/queries';
import { useAppDispatch } from '@/store';

import { promoBannerPolicies } from './apolloPolicies';
import { allFitters } from './fitters';
import { setAll } from './slice';

export type TUsePromoBannerUpdaterArgs = {
  beforeStart?: number;
  isEnabled?: boolean;
  repeatInterval: number;
};

const usePromoBannerUpdater = ({ beforeStart, isEnabled, repeatInterval }: TUsePromoBannerUpdaterArgs) => {
  const dispatch = useAppDispatch();

  const onResult = (answer: GQLPromoBannersApiAnswer) => {
    dispatch(setAll({ data: allFitters(answer?.promoBannersV2) || [], error: undefined, isLoaded: true }));
  };

  const onError = (error: Record<string, unknown>) => dispatch(setAll({ data: [], error }));

  useGraphQLUpdater<GQLPromoBannersApiAnswer>({
    apolloArgs: { typePolicies: promoBannerPolicies },
    beforeStart,
    isEnabled,
    onError,
    onResult,
    query: FETCH_PROMO_BANNERS_V2,
    repeatInterval,
  });
};

export default usePromoBannerUpdater;
