import { type TOptional } from '@/types';

const isPathMatched = (pattern: string, path: string): TOptional<boolean> => {
  if (pattern && path) {
    if (path === pattern) return true;
    if (pattern.includes('*')) {
      const [start, end] = pattern.split('*');
      return (!start || path.startsWith(start!)) && (!end || path.endsWith(end!));
    }
  }
};

export default isPathMatched;
