import { env } from '@/environment';

export const googleTagManagerCode = (w = window, d = document, s = 'script', l = 'dataLayer', i = env.GTM_ID) => {
  if (i) {
    // eslint-disable-next-line no-param-reassign
    w[l] = w[l] || [];
    w[l].push({
      event: 'gtm.js',
      'gtm.start': new Date().getTime(),
    });
    const f = d.getElementsByTagName(s)[0];
    const j = d.createElement(s);
    const dl = l !== 'dataLayer' ? `&l=${l}` : '';
    j.async = true;
    j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;
    f.parentNode.insertBefore(j, f);
  }
};

export default googleTagManagerCode;
