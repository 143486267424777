import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import { useDispatch } from 'react-redux';

import Tooltip, { TooltipMode } from '@/components/Tooltip';
import UIResource from '@/components/UIResource';
import UIResourceHtml from '@/components/UIResource/Html';
import { resetFmFlow } from '@/ducks/fm/reset';
import tagmanager from '@/tagmanager';

const {
  tracker: {
    common: { trackIconClick, trackLinkClick },
  },
  trackerConstants,
} = tagmanager;

const onOpenTooltip = () => {
  trackIconClick({ label: trackerConstants.EVENT_LABELS.FM_INFO });
};

const FMAgencyDetailTitle = ({ agentName }) => {
  const dispatch = useDispatch();

  const optOut = useCallback(() => {
    dispatch(resetFmFlow());
    trackLinkClick({ label: trackerConstants.EVENT_LABELS.FM_OPT_OUT });
  }, [dispatch]);

  return (
    <div className="title">
      <span className="titleWrapper">
        <UIResource id="FMLinking.Banner.Title" />
        {` ${agentName || ''}`}
      </span>
      <Tooltip
        isInline
        mode={TooltipMode.INFO}
        onOpen={onOpenTooltip}
        openerClassName="FM__tooltipOpener"
        popupClassName="FM__tooltipContent"
        side="bottom"
      >
        <UIResourceHtml
          className="tooltipParagraph"
          id="FMLinking.tooltip"
          node="span"
          values={{ Name: agentName || '' }}
        />
        <span className="tooltipParagraph">
          <UIResource id="FMLinking.optOut" />
          <span className="optOutButton" onClick={optOut} role="button" tabIndex={0} variant="link">
            <UIResource id="FMLinking.optOutButton" />
          </span>
        </span>
      </Tooltip>
    </div>
  );
};

FMAgencyDetailTitle.propTypes = {
  agentName: PropTypes.string,
};

export default FMAgencyDetailTitle;
