'use client';

import { usePathname, useSearchParams } from 'next/navigation';
import { useEffect, useMemo } from 'react';

import { AppProgressBar as ProgressBar } from 'next-nprogress-bar';
import { Provider } from 'react-redux';

import type { VWOFeatureFlags } from '@/types/vwo';

import FMBanner from '@/components/FMBanner';
import { BookingChannelTypes } from '@/constants/settings';
import MobileDeviceContext from '@/contexts/MobileDeviceContext';
import VWOGlobalFeatureContext from '@/contexts/VWOGlobalFeatureContext';
import { useFiltersValuesInit } from '@/ducks/filters/hooks';
import { selectAgentBookingChannel, selectAgentDetailsIsValid } from '@/ducks/fm/selectors';
import { removeAgencyData, setAgencyData } from '@/ducks/fm/setters';
import { AgentDataActionType } from '@/ducks/fm/types';
import useStorePersistentAligners from '@/ducks/persistent/useStorePersistentAligners';
import { checkAllProgramKeys } from '@/ducks/programs/all/actions';
import useRouteChangeObserver from '@/ducks/routeChange/useRouteChangeObserver';
import { setPathname, setRouter, setSearchParams } from '@/ducks/routes/history';
import EnterByLinkRedirectGuard from '@/features/enterByLink/RedirectGuard';
import usePromoBannerUpdater from '@/features/promoBanners/usePromoBannerUpdater';
import useRouteConfig from '@/features/routeConfig/useRouteConfig';
import useStrikethroughUpdater from '@/features/strikethrough/useStrikethroughUpdater';
import { ApolloWrapper } from '@/helpers/api/graphql/ApolloWrapper';
import { AppAuthenticator, type AppTokens } from '@/helpers/api/tokens';
import CMSContextProvider from '@/helpers/cms/CMSContextProvider';
import { FormattedMessageProvider } from '@/helpers/formatted-message';
import { googleTagManagerCode, vwoInitCode } from '@/helpers/inlineScripts';
import useInitAccountDropdownEvents from '@/helpers/managers/account-dropdown/useInitAccountDropdownEvents';
import { saveIsTaxesIncludedToStorage } from '@/helpers/pricing/lib/tax';
import { toBlobPolyfill } from '@/helpers/util';
import { initLogger } from '@/helpers/util/logger';
import { useAsyncRouter } from '@/hooks/router/useAsyncRouter';
import { useCatchAsyncError } from '@/hooks/useAsyncError';
import { useIosTextFieldFocusZoomPatch } from '@/hooks/useIosTextFieldFocusZoomPatch';
import useOnceOnClient from '@/hooks/useOnceOnClient';
import usePrevPathPreservation from '@/hooks/usePrevPathPreservation';
import { useSetExplicitSearchParams } from '@/hooks/useSetExplicitSearchParams';
import { useInitUplift } from '@/infra/uplift';
import WithChatbot from '@/layouts/Wrappers/Chatbot';
import { type RootState, setupStore, useAppDispatch } from '@/store';
import tagmanager from '@/tagmanager';
import { ApplyPromoColors } from '@/widgets/Promo/ApplyPromoColors';

import './Base.scss';

const PROMO_BANNERS_UPDATE_INTERVAL = 900; // In seconds
const STRIKETHROUGH_UPDATE_INTERVAL = 900; // In seconds

type BaseLayoutProps = {
  children: React.ReactNode;
  tokens: AppTokens;
};

const BaseLayout = ({ children, tokens }: BaseLayoutProps) => {
  AppAuthenticator.getInstance().setRawTokens(tokens);
  const dispatch = useAppDispatch();
  const pathname = usePathname();
  const routeConfig = useRouteConfig();

  useFiltersValuesInit();
  useSetExplicitSearchParams();

  useRouteChangeObserver();
  useOnceOnClient(initLogger);
  useCatchAsyncError();

  useEffect(() => {
    tagmanager.tracker.anyBookFunnel.trackPageOpen(pathname);
  }, [pathname]);

  useEffect(() => {
    googleTagManagerCode();
    toBlobPolyfill();
    vwoInitCode();

    dispatch(checkAllProgramKeys());
  }, []);

  useStorePersistentAligners();
  useInitUplift();
  usePrevPathPreservation();
  useInitAccountDropdownEvents();
  usePromoBannerUpdater({
    isEnabled: !!routeConfig?.bannerPlaces?.length,
    repeatInterval: PROMO_BANNERS_UPDATE_INTERVAL,
  });
  useStrikethroughUpdater({
    isEnabled: true,
    repeatInterval: STRIKETHROUGH_UPDATE_INTERVAL,
  });

  return (
    <>
      <FMBanner />
      <div className="Layout__content">
        <WithChatbot>{children}</WithChatbot>
      </div>
      <ProgressBar color="#c00" height="2px" options={{ showSpinner: false }} shallowRouting />
    </>
  );
};

type BaseLayoutWrapperProps = {
  agentAction?: string;
  campaign: Record<string, unknown>;
  children: React.ReactNode;
  featureFlags: Record<string, boolean>;
  initialState: RootState;
  isMobile: boolean;
  legalContent: Record<string, unknown>;
  tokens: AppTokens;
};

const useHackForRouter = () => {
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const router = useAsyncRouter();

  setSearchParams(searchParams);
  setPathname(pathname);
  setRouter(router);
};

const useSetupStore = (initialState: RootState) => {
  return useMemo(
    () => setupStore(initialState, { reconfigure: true }),
    // Don't add deps as it will trigger making store from scratch
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
};

const useUpdateAgentDetails = ({ action, initialState }: { action?: string; initialState: RootState }) => {
  useEffect(() => {
    if (action === AgentDataActionType.remove) {
      removeAgencyData();
    } else if (initialState && action !== AgentDataActionType.exist) {
      removeAgencyData();
      if (
        selectAgentDetailsIsValid(initialState) &&
        selectAgentBookingChannel(initialState) === BookingChannelTypes.fmLink
      ) {
        setAgencyData(initialState);
      }
    }
  }, [action, initialState]);
};

const BaseLayoutWrapper = ({
  agentAction,
  campaign,
  children,
  featureFlags,
  initialState,
  isMobile,
  legalContent,
  tokens,
}: BaseLayoutWrapperProps) => {
  useHackForRouter();
  useIosTextFieldFocusZoomPatch();
  saveIsTaxesIncludedToStorage(featureFlags as VWOFeatureFlags);

  AppAuthenticator.getInstance().setRawTokens(tokens);
  useUpdateAgentDetails({ action: agentAction, initialState });
  const store = useSetupStore(initialState);

  return (
    <Provider store={store}>
      <EnterByLinkRedirectGuard>
        <VWOGlobalFeatureContext.Provider value={featureFlags}>
          <ApolloWrapper>
            <ApplyPromoColors />
            <MobileDeviceContext.Provider value={isMobile}>
              <FormattedMessageProvider>
                <CMSContextProvider campaign={campaign} legalContent={legalContent}>
                  <BaseLayout tokens={tokens}>{children}</BaseLayout>
                </CMSContextProvider>
              </FormattedMessageProvider>
            </MobileDeviceContext.Provider>
          </ApolloWrapper>
        </VWOGlobalFeatureContext.Provider>
      </EnterByLinkRedirectGuard>
    </Provider>
  );
};

export default BaseLayoutWrapper;
