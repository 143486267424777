import type { TPromoBannerCtaLink } from '@/features/promoBanners/types';
import type { GQLCta } from '@/helpers/api/graphql/queries';
import type { TOptional } from '@/types/common';

export type TCtaContainer = Partial<Omit<GQLCta, 'ctaTarget'>> & Pick<GQLCta, 'ctaTarget'>;

export type TGatherCta<D extends TCtaContainer> = {
  cta: TOptional<TPromoBannerCtaLink>;
} & Omit<D, keyof TCtaContainer>;

const gatherCTA = <D extends TCtaContainer>(data: D): TGatherCta<D> => {
  const { ctaStyle, ctaTarget, ctaText, ...restProps } = data || {};
  return {
    ...restProps,
    cta:
      ctaText || ctaTarget
        ? { appearance: ctaStyle || undefined, label: ctaText || undefined, url: ctaTarget || undefined }
        : undefined,
  };
};

export default gatherCTA;
