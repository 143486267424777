import type { DocumentNode, InMemoryCacheConfig, OperationVariables, TypedDocumentNode } from '@apollo/client';

import { useEffect, useRef } from 'react';

import { useLazyQuery } from '@apollo/client';

import { apolloErrorToJson } from './errorsToJson';
import makeApolloClient from './makeApolloClient';

export type TUseGraphQLUpdaterArgs<TData, TVars> = {
  apolloArgs?: InMemoryCacheConfig;
  beforeStart?: number;
  isEnabled?: boolean;
  onError?: (error: Record<string, unknown>) => void;
  onResult?: (data: TData) => void;
  query: DocumentNode | TypedDocumentNode<TData, TVars>;
  repeatInterval: number;
};

const useGraphQLUpdater = <TData, TVars extends OperationVariables = OperationVariables>({
  apolloArgs,
  beforeStart,
  isEnabled,
  onError,
  onResult,
  query,
  repeatInterval,
}: TUseGraphQLUpdaterArgs<TData, TVars>) => {
  const apolloRef = useRef<ReturnType<typeof makeApolloClient>>();
  const isMountedRef = useRef<boolean>();
  const delayRef = useRef<NodeJS.Timeout>();

  if (!apolloRef.current) {
    apolloRef.current = makeApolloClient({ cacheOptions: apolloArgs, isClientOnly: true, withAuth: true });
  }

  const [, { data: answer, error: err, startPolling, stopPolling }] = useLazyQuery<TData, TVars>(query, {
    client: apolloRef.current,
    context: { fetchOptions: { cache: 'no-store' } },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    ssr: false,
  });

  const dropDelay = () => {
    if (delayRef.current) clearTimeout(delayRef.current);
  };

  const start = () => {
    dropDelay();
    delayRef.current = setTimeout(
      () => {
        dropDelay();
        startPolling(1000 * repeatInterval);
      },
      isMountedRef.current ? 0 : 1000 * (beforeStart ?? repeatInterval),
    );
  };

  const stop = () => {
    dropDelay();
    stopPolling();
  };

  useEffect(() => {
    if (isEnabled) start();
    else stop();
  }, [isEnabled]);

  useEffect(() => {
    isMountedRef.current = true;
    return stop;
  }, []);

  useEffect(() => {
    if (err) {
      const error = apolloErrorToJson(err);
      if (error) onError?.(error);
    } else if (answer) {
      onResult?.(answer);
    }
  }, [answer, err]);
};

export default useGraphQLUpdater;
