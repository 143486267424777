import { RedirectType } from 'next/dist/client/components/redirect';
import { redirect as nextRedirect } from 'next/navigation';

import { isServerSide } from '@/helpers/isServerSide';
import getCurrentSearchParams from '@/helpers/url/getCurrentSearchParams';
import makeAbsUrlByRouteUrl from '@/helpers/url/makeAbsUrlByRouteUrl';

export type TRedirectOptions = {
  isReplace?: boolean;
  withParams?: boolean;
};

const redirect = (routePath: string, options?: TRedirectOptions) => {
  const { isReplace, withParams } = options ?? {};
  const url = makeAbsUrlByRouteUrl(routePath, withParams ? getCurrentSearchParams() : undefined);
  if (url) {
    if (isServerSide()) nextRedirect(url.toString(), isReplace ? RedirectType.replace : RedirectType.push);
    else if (isReplace) window.location.replace(url);
    else window.location.assign(url);
  }
};

export default redirect;
