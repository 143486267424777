import type { TOptional } from '@/types';

import { env } from '@/environment';
import { getIsProdBuild } from '@/helpers/util/env';

import addToSearchParams from './addToSearchParams';
import getCurrentOrigin from './getCurrentOrigin';

const makeAbsUrlByRouteUrl = (
  relativeUrl: string,
  params?: TOptional<Record<string, unknown> | URLSearchParams>,
): TOptional<URL> => {
  const origin = getIsProdBuild() ? `https://${env.ORIGIN}` : getCurrentOrigin();
  const context = env.CONTEXT;
  if (origin && context) {
    let url;
    try {
      url = new URL(`${context}${relativeUrl}`, origin);
    } catch {
      return undefined;
    }
    if (params) addToSearchParams(url.searchParams, params);
    return url;
  }
};

export default makeAbsUrlByRouteUrl;
