import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import set from 'lodash/set';

import type { TOptional } from '@/types';

import { isServerSide } from '@/helpers/isServerSide';

export const getStorage = (isSession?: TOptional<boolean>): TOptional<Storage> => {
  if (!isServerSide()) {
    return isSession ? (window as WindowSessionStorage).sessionStorage : (window as WindowLocalStorage).localStorage;
  }
};

export const hasStorages = () => !!getStorage() && !!getStorage(true);

export const getByName = <V = unknown>(name: string, inSession?: TOptional<boolean>): TOptional<V> => {
  const storage = getStorage(inSession);
  if (storage) {
    const value = storage.getItem(name);
    if (value !== null) {
      try {
        return JSON.parse(value) as V;
      } catch {
        /* nothing */
      }
    }
  }
};

export const getByPath = <V = unknown>(path: string[], inSession?: TOptional<boolean>): TOptional<V> => {
  if (getStorage(inSession)) {
    if (path.length && path.every(Boolean)) {
      const [first, ...inner] = path;
      const data = getByName(first!, inSession);
      if (data !== undefined) return (inner.length ? get(data, inner) : data) as V;
    }
  }
};

export const getValue = <V = unknown>(nameOrPath: string | string[], inSession?: TOptional<boolean>): TOptional<V> =>
  Array.isArray(nameOrPath)
    ? getByPath<V>(nameOrPath as string[], inSession)
    : getByName<V>(nameOrPath as string, inSession);

export const setByName = (name: string, value: unknown, inSession?: TOptional<boolean>): void => {
  const storage = getStorage(inSession);
  if (storage) {
    if (value !== null && value !== undefined) {
      try {
        const next = JSON.stringify(value);
        const prev = storage.getItem(name);
        if (next !== prev) storage.setItem(name, next);
      } catch {
        /* nothing */
      }
    } else {
      storage.removeItem(name);
    }
  }
};

export const setByPath = (path: string[], value: unknown, inSession?: TOptional<boolean>): void => {
  if (getStorage(inSession)) {
    if (path.length && path.every(Boolean)) {
      const [first, ...inner] = path;
      if (inner.length) {
        const data = getByName<Record<string, unknown>>(first!, inSession);
        if (data) {
          const prev = get(data, inner);
          if (!isEqual(value, prev)) {
            set(data, inner, value);
            setByName(first!, data, inSession);
          }
        } else {
          const initial = set({}, inner, value);
          setByName(first!, initial, inSession);
        }
      } else {
        setByName(first!, value, inSession);
      }
    }
  }
};

export const setValue = (nameOrPath: string | string[], value: unknown, inSession?: TOptional<boolean>): void => {
  if (Array.isArray(nameOrPath)) setByPath(nameOrPath as string[], value, inSession);
  else setByName(nameOrPath as string, value, inSession);
};
