import { usePathname, useSearchParams } from 'next/navigation';
import { useEffect, useRef } from 'react';

import { useDispatch } from 'react-redux';

import { savePrevPath, savePrevSearchParams } from '@/ducks/page';

const usePrevPathPreservation = () => {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const prevSearchParams = useRef('');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(savePrevPath(pathname));

    dispatch(savePrevSearchParams(prevSearchParams.current.toString()));
    prevSearchParams.current = searchParams;
  }, [dispatch, pathname, searchParams]);
};

export default usePrevPathPreservation;
