import { usePathname, useSearchParams } from 'next/navigation';
import { useEffect } from 'react';

import { isEqual } from 'lodash';

import { selectFmSearchParams } from '@/ducks/fm/selectors';
import { selectMnvvSearchParams } from '@/ducks/mnvv/selectors';
import createMemoSelector from '@/helpers/createMemoSelector';
import { ensureArray } from '@/helpers/util';
import { useAppSelector } from '@/store';

const selectExplicitSearchParams = createMemoSelector(
  selectFmSearchParams,
  selectMnvvSearchParams,
  (fm, mnvv) =>
    ({
      ...fm,
      ...mnvv,
    }) as Record<string, string | undefined>,
);

export const useSetExplicitSearchParams = () => {
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const explicitSearchParams = useAppSelector(selectExplicitSearchParams);

  useEffect(() => {
    let updated = false;
    const newSearchParams = new URLSearchParams(searchParams);

    Object.keys(explicitSearchParams).forEach((key) => {
      const values = (ensureArray(explicitSearchParams[key]) || []) as string[];

      if (!searchParams.has(key) || !isEqual(searchParams.getAll(key), values)) {
        updated = true;
        newSearchParams.delete(key);
        values?.forEach((value) => newSearchParams.append(key, value as string));
      }
    });

    if (updated) {
      const q = newSearchParams.toString();
      history.replaceState(null, '', `${window.location.pathname}${q ? `?${q}` : ''}`);
    }
  }, [searchParams, explicitSearchParams, pathname]);
};
