import React, { useMemo } from 'react';

import { BookingChannelTypes } from '@/constants/settings';
import { useUIResource } from '@/ducks/common/resources';
import { selectAgentDetails } from '@/ducks/fm/selectors';
import { useAppSelector } from '@/store';

import ShareEmail from '../Icon/ShareEmail';
import FMAgencyDetailTitle from './Title';

import './FMAgencyDetail.scss';

const FMAgencyDetail = () => {
  const emailSubject = useUIResource('FMLinking.Banner.email.subject');
  const agentData = useAppSelector(selectAgentDetails) || {};

  const mailtoUrl = useMemo(
    () => (agentData?.email ? `mailto:${agentData?.email}?subject=${emailSubject}` : undefined),
    [agentData?.email, emailSubject],
  );
  const phoneUrl = `tel:+${agentData?.code} ${agentData?.phoneNumber}`;

  if (!agentData?.isValid || agentData?.bookingChannel !== BookingChannelTypes.fmLink) {
    return null;
  }

  return (
    <section className="FM__Main">
      <div className="FM__agencyDetail">
        <FMAgencyDetailTitle agentName={agentData?.agentName} />
      </div>
      <div className="FM__agentDetail">
        <div className="personalDetail">
          <p className="email">
            <a
              aria-label="Email"
              data-sharetype="FMEmail"
              href={mailtoUrl}
              rel="noopener noreferrer"
              tabIndex="-1"
              target="_blank"
            >
              <ShareEmail ariaLabel="FM-ShareEmail" glyph="FM__ShareEmail" />
            </a>
          </p>
          <p className="name">
            <a aria-label="Email" href={mailtoUrl} rel="noopener noreferrer" tabIndex="-1" target="_blank">
              {` ${agentData?.agentName || ''}`}
            </a>
          </p>
        </div>
        <p className="phone">
          <a
            aria-label="Email"
            className="phoneLink"
            data-sharetype="FMEmail"
            href={phoneUrl}
            rel="noopener noreferrer"
            tabIndex="-1"
            target="_blank"
          >
            {` +${agentData?.code || ''}${agentData?.phoneNumber || ''}`}
          </a>
        </p>
      </div>
    </section>
  );
};

export default FMAgencyDetail;
