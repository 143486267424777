import { type ReactNode } from 'react';

import Spinner from '@/components/elements/Spinner';
import useRouteConfig from '@/features/routeConfig/useRouteConfig';

import useRedirectOnEnterByLink from './useRedirectOnEnterByLink';

type TProps = {
  children: ReactNode;
};

const EnterByLinkRedirectGuard = ({ children }: TProps) => {
  const routeConfig = useRouteConfig();
  const isRedirected = useRedirectOnEnterByLink(routeConfig);

  if (isRedirected) return <Spinner shouldFixed />;
  return children;
};

export default EnterByLinkRedirectGuard;
