import { useEffect } from 'react';

import getOSName, { OSName } from '@/helpers/userAgent/getOSName';

const MAX_SCALE_REGEXP = /maximum-scale=[0-9.]+/g;
const MAX_SCALE_1_STRING = 'maximum-scale=1.0';

const checkIsIOS = () => getOSName() === OSName.iOS;

const disableIosTextFieldZoom = () => {
  const el = document.querySelector('meta[name=viewport]');

  if (el !== null) {
    let content = el.getAttribute('content') || '';

    if (MAX_SCALE_REGEXP.test(content)) {
      content = content.replace(MAX_SCALE_REGEXP, MAX_SCALE_1_STRING);
    } else {
      content = [content, MAX_SCALE_1_STRING].join(', ');
    }
    el.setAttribute('content', content);
  }
};

export const useIosTextFieldFocusZoomPatch = () => {
  useEffect(() => {
    checkIsIOS() && disableIosTextFieldZoom();
  }, []);
};
